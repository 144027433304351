<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">外研教学评儿童隐私保护声明</div>
    </div>
    <div class="main">
      <p>本版本生效于：2023年7月 24 日</p>
      <br />
      <div class="h1">引言</div>
      <p>
        外语教学与研究出版社有限责任公司（以下简称“外研社”或“我们”）致力于保护使用我们产品或服务的儿童的个人信息。为了更好地保护儿童个人信息，除特定服务和/或产品具体的隐私政策以外，我们还将通过《儿童隐私保护声明》（以下简称“本声明”）向儿童用户的监护人（以下简称“您”或“监护人”）和儿童说明我们如何收集、使用、存储、使用及对外提供儿童的个人信息，以及我们为您与儿童提供的访问、更正、删除和保护这些信息的方式。
      </p>
      <p class="underL weight">
        在使用我们各项产品或服务之前，请您和儿童务必仔细阅读并透彻理解本声明。本声明中与您和儿童的权益（可能）密切相关的重要条款，我们已采用加粗字体及下划线作标识，请重点查阅，并在确认充分理解和同意全部条款后再开始使用。
      </p>
      <p class="underL">
        除另有约定外，本声明所用术语和缩略词与特定服务和/或产品具体的隐私政策中的术语和缩略词具有相同的涵义。
      </p>
      <br />
      <p class="weight">监护人特别说明：</p>
      <p>
        如果您是儿童用户的监护人，请您仔细阅读和选择是否同意本声明。我们希望请您与我们共同保护儿童的个人信息，教育引导儿童增强个人信息保护意识和能力，指导、提醒和要求他们在未经您同意的情况下，不向任何网络产品和服务提供者提供任何个人信息。
      </p>
      <p class="underL weight">
        请您在仔细阅读、充分理解具体产品或服务的《隐私政策》和本声明后，选择是否同意相关隐私政策以及是否同意您和您监护的儿童使用我们的产品或服务。如果您不同意相关隐私政策和本声明的内容或不同意提供服务所必要的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。
      </p>
      <p class="underL weight">
        您于线上点击“同意”、或您监护的儿童使用/继续使用我们的产品或服务、提交个人信息均表示您同意我们按照具体产品或服务的隐私政策和本声明（包括更新版本，如有）的约定收集、使用、存储和分享您监护的儿童的信息。
      </p>
      <p class="weight">儿童特别说明：</p>
      <p>
        如果您是14周岁以下的未成年人，您需要和您的监护人一起仔细阅读本声明，并在征得您的监护人同意后，使用我们的产品、服务或向我们提供信息。
      </p>
      <p class="weight">
        您于线上点击“同意”或“下一步”，或使用/继续使用我们的产品或服务、提交个人信息，均表示您已获得您的监护人的许可，且您的监护人均同意我们按照具体产品或服务的《隐私政策》和本声明（包括更新版本）的约定收集、使用、存储和分享您的个人信息。
      </p>
      <p>
        本声明系在
        <span class="weight">具体产品或服务的《隐私政策》</span
        >基础上针对儿童个人信息制定的特别规则，与
        <span class="weight">《隐私政策》</span
        >如有不一致之处，以本声明为准；本声明未载明之处，则参照适用
        <span class="weight">《隐私政策》</span>。
      </p>
      <p>
        在阅读本声明后，如对本声明或与儿童个人信息相关事宜有任何疑问的，可通过我们提供的专门反馈渠道（具体参见本声明“如何联系我们”章节）联系我们，我们会尽快作出解答。
      </p>
      <p>本声明所称儿童，是指不满十四周岁的未成年人。</p>
      <br />
      <p>本声明将帮助您了解以下内容：</p>
      <p>第一章 我们如何收集和使用儿童个人信息</p>
      <p>第二章 我们如何委托处理、共享、转让或披露儿童个人信息</p>
      <p>第三章 我们如何储存和保护儿童个人信息</p>
      <p>第四章 如何管理儿童个人信息</p>
      <p>第五章 本声明的适用及更新</p>
      <p>第六章 如何联系我们</p>
      <div class="h1">第一章 我们如何收集和使用儿童个人信息</div>
      <p>
        1.在儿童使用我们产品或服务的过程中，我们会严格履行法律法规规定的儿童个人信息保护义务与责任，在征得监护人的同意后收集和使用儿童个人信息，以实现特定的产品功能或达到拟达到的服务效果。
      </p>
      <p class="underL weight">
        2.为更好地保护儿童的权益，必要时我们可能会收集监护人的联系方式（包括手机号码、电子邮箱）以及身份信息（包括姓名、身份证、户口本等其他监护关系证明），以便进一步验证监护人与儿童的监护关系。
      </p>
      <p>
        3.我们将在各项产品或服务的《隐私政策》中，结合具体场景说明需要用户同意我们收集的信息类型、信息收集的目的或用途以及拒绝同意的后果。具体请查阅各项产品或服务的
        <span class="underL weight"
          ><router-link target="_blank" to="/privacy">
            《隐私政策》
          </router-link>
          中“我们如何收集和使用您的个人信息”</span
        >
        进行详细了解。如我们需要超出上述范围收集和使用儿童个人信息，我们将再次征得监护人的同意。
      </p>
      <p>
        4.我们提供的与儿童有关的服务是不断更新和发展的，可能出现用户选择使用的各产品或服务的《隐私政策》及本声明中尚未涵盖的其他服务，基于该服务我们需要收集儿童信息的，我们会通过页面提示、交互流程、协议约定的方式另行说明信息收集的范围、目的与用途，并征得监护人的同意。
      </p>
      <p class="underL weight">
        请您理解，您可以选择是否填写或向我们提供特定的信息，但您如果不填写或提供某些特定的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。
      </p>
      <div class="h1">
        第二章 我们如何委托处理、共享、转让或披露儿童个人信息
      </div>
      <p>
        我们承诺对儿童个人信息进行严格保密，仅在符合具体产品/服务的《隐私政策》、本声明以及您明确授权同意的情况下委托处理、我们遵照法律法规的规定，严格限制共享、转移、披露儿童个人信息的情形。我们将在各项产品或服务的《隐私政策》中说明需要监护人同意共享、转让、披露儿童个人信息的情形以及拒绝同意的后果。具体请查阅各项产品或服务的
        <span class="underL weight"
          ><router-link target="_blank" to="/privacy">
            《隐私政策》 </router-link
          >中的“我们如何共享、转让、披露您的个人信息”</span
        >
        进行详细了解。
      </p>
      <p>
        1.一般情况下，我们不会向第三方分享儿童的个人信息。我们仅在监护人事先同意的情况下，才可能与第三方分享儿童的个人信息。
      </p>
      <p>
        2.我们会将部分儿童个人信息（包括儿童用户名、帐号、密码，以及该儿童用户在使用我们某些产品和服务中产生的交易等内容）与监护人共享，监护人可以访问和管理儿童在各产品内的个人信息和学习数据。
      </p>
      <p>
        3.各产品《隐私政策》当中“我们如何共享、转让、公开披露您的个人信息”一节约定的共享、转让、公开披露情形。我们将按照法律法规及国家标准的要求征得监护人的同意。
      </p>
      <p>
        4.如我们需要委托第三方处理儿童个人信息，我们会对受委托方及委托行为等进行安全评估，签署委托协议和保密协定，明确双方责任、处理事项、处理期限、处理性质和目的，要求受委托方的处理行为不得超出我们的授权范围，并按照我们的说明、本声明以及其他任何相关的保密和安全措施来处理共享的信息。我们委托第三方处理儿童个人信息主要用于以下用途：
      </p>
      <p>（1）向儿童提供我们的服务；</p>
      <p>（2）实现“我们如何收集和使用儿童个人信息”部分所述目的；</p>
      <p>
        （3）履行我们在各项产品或服务的《隐私政策》和本声明中的义务和行使我们的权利；
      </p>
      <p>
        如我们与上述第三方分享您和儿童的信息，我们将进行安全评估并采用加密、匿名化、去标识化处理等手段保障您和儿童的信息安全。
      </p>
      <p>
        5.随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您和儿童的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本声明所要求的标准继续保护或要求新的管理者继续保护您和儿童的个人信息。否则，我们将要求相关方组织重新征求您的授权同意。
      </p>
      <p>
        6.我们可能会将所收集到的儿童信息在进行匿名化处理后用于大数据分析，形成不包含任何个人信息的热力图、洞察报告等。
      </p>
      <p>
        7.我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。
      </p>
      <p>8. 一般情况下，我们禁止对外披露儿童个人信息，除非获得监护人的同意。</p>

      <div class="h1">第三章 我们如何存储和保护儿童信息</div>
      <p>
        1.我们会遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，采取加密等技术措施存储儿童个人信息，确保信息安全，存储儿童信息不会超过实现信息收集、使用目的所必需的期限，到期后，一般情况下，我们将会十五个工作日内对儿童个人信息进行删除或匿名化处理。同时，我们对工作人员以最小授权为原则，严格设定信息访问权限，控制儿童个人信息知悉范围。请查阅各产品
        <span class="underL weight"
          ><router-link target="_blank" to="/privacy">
            《隐私政策》 </router-link
          >“我们如何保护和保存您的个人信息”</span
        >
        章节，详细了解我们如何存储和保护儿童个人信息。
      </p>
      <p>
        2.儿童终止使用我们的各项产品或服务后，我们会及时停止对该儿童用户个人信息的收集和使用，法律法规或监管部门另有规定的除外。当我们终止服务或停止运营，或您和儿童的个人信息存储期限届满时，我们将采取推送通知、公告等形式通知您，一般情况下，我们将会在十五个工作日内删除或匿名化处理您和儿童的个人信息，但法律法规或监管部门另有规定的除外。
      </p>
      <p>
        3.
        <span class="weight"
          >请您和您的儿童务必妥善保管好帐号、密码及其他身份要素。儿童在使用我们的产品或服务时，我们会通过帐号、密码及及其他身份要素来识别其身份。一旦您和儿童泄露了前述信息，可能会蒙受损失，并可能对自身产生不利影响。</span
        >如您和儿童发现帐号、密码及/或其他身份要素可能或已经泄露时，请立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
      </p>
      <div class="h1">第四章 如何管理儿童信息</div>
      <p>
        1.在儿童使用我们的产品或服务期间，
        <span class="underL weight">
          为了儿童可以更加便捷地访问和管理信息，同时保障儿童注销账户的权利，我们在客户端中为儿童提供了相应的操作设置，儿童可以按照各产品《隐私政策》当中的指引进行操作。</span
        >同时，监护人可以通过指导儿童进行操作，通过在本声明载明的联系方式联系我们，要求对儿童信息进行查询、更正或删除。
      </p>
      <p>2.下列情形下，儿童及其监护人可联系我们，要求删除相应的儿童信息：</p>
      <p>
        （1）我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、对外提供儿童信息的；
      </p>
      <p>
        （2）如果我们收集、使用监护人与孩子的个人信息，却未征得监护人与孩子的同意；
      </p>
      <p>（3）超出目的范围或者必要期限收集、存储、使用、对外提供儿童信息的；</p>
      <p>（4）儿童监护人撤回同意的；</p>
      <p>（5）儿童或者其监护人通过注销等方式终止使用产品或者服务的；</p>
      <p>（6）如果我们不再为监护人与孩子提供产品或服务。</p>
      <p>
        若我们决定响应监护人与孩子的删除请求，我们还将同时通知从我们获得个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得监护人与孩子的独立授权。
      </p>
      <p>
        当监护人与孩子从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。
      </p>
      <p>
        如法律、行政法规规定的保存期限未届满，或者删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。
      </p>
      <p class="weight">
        请注意，若监护人与孩子要求我们删除特定的用户个人信息，可能导致该用户无法继续使用我们的产品和服务，或导致产品和服务中的某些业务功能无法实现。
      </p>
      <p>3.改变、撤回监护人授权同意的范围</p>
      <p>
        您和儿童可通过与我们联系改变您授权同意的范围或撤回授权。具体的改变授权同意的范围或撤回授权的方式将取决于您和儿童使用的单项服务。
        <span class="underL weight">
          具体可参见在具体产品的
          <router-link target="_blank" to="/privacy"> 《隐私政策》 </router-link
          >中“改变您授权同意的范围或撤回授权”。
        </span>
      </p>
      <p class="weight">
        当您和儿童撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的个人信息。但您改变授权同意范围或撤回授权的决定，并不影响我们此前基于您的授权而开展的个人信息处理。
      </p>
      <p>4.注销帐号</p>
      <p>
        如果您的儿童不再使用我们的产品或服务，在符合服务协议约定条件及国家相关法律法规规定的情况下，儿童和监护人可以在账户设置中提出注销申请或与我们联系提出注销申请。当您的儿童帐号被注销后，我们将停止提供服务，并将与该帐号相关的外研社服务项下的全部服务资料和数据将被删除或匿名化处理，但法律法规另有规定除外。
      </p>
      <p>5.获取个人信息副本</p>
      <p>
        监护人与孩子有权获取个人信息副本，可以通过本声明载明的联系方式联系我们，要求我们提供。在技术可行的前提下，例如数据接口匹配，我们还可按监护人与孩子的要求，直接将个人信息副本传输给监护人与孩子指定的第三方
        。
      </p>
      <p>6.约束信息系统自动决策结果</p>
      <p>
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响监护人与孩子的合法权益，监护人与孩子有权要求我们作出解释或对信息系统自动化决策的结果进行申诉，可以通过本声明载明的联系方式与我们取得联系或向我们进行申诉，我们也将在不侵害本产品商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。
      </p>
      <p>7.投诉与举报</p>
      <p>
        我们设置了投诉举报渠道，监护人与孩子的意见将会得到及时的处理。监护人与孩子可以本隐私政策载明的联系方式进行投诉和举报。
      </p>
      <p>8.我们如何响应上述请求</p>
      <p>
        为保障安全，监护人与孩子可能需要提供书面请求，或以其他方式证明监护人与孩子的身份。
        我们可能会先要求监护人验证自己的身份，然后再处理监护人与孩子的请求。
      </p>
      <p>
        我们将在15个工作日内做出答复。对于合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如，涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。
      </p>
      <p>在以下情形中，我们将无法响应请求：</p>
      <p>(1)与个人信息控制者履行法律法规规定的义务相关的；</p>
      <p>(2)与国家安全、国防安全直接相关的；</p>
      <p>(3)与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>(4)与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
      <p>
        (5)个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
      </p>
      <p>
        (6)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p>
        (7)响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；
      </p>
      <p>(8)涉及商业秘密的。</p>
      <div class="h1">第五章 本声明的适用及更新</div>
      <p class="underL">
        1.
        为了提供更好的服务，我们可能适时更新本政策。我们会通过在官网或官方起到公布更新版本或者提示您更新的内容。我们在生效前我们将通过弹窗或以其他适当方式提醒您与儿童相关内容的更新，也请您及时访问我们的网站，以便及时了解最新的政策。
      </p>
      <p class="underL">
        2.
        对于重大变更，我们还会为您提供更为显著的通知，例如我们会通过弹窗、私信或在您浏览页面做特别提示等方式说明政策的具体变更内容。为了您能及时接收到通知，建议您及时访问或更新我们的产品及服务。本声明所指的重大变更包括但不限于：
      </p>
      <p class="underL">
        (1)
        我们的所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p class="underL">
        (2) 收集、存储、使用、对外提供儿童信息的目的、方式和范围发生实质性变化；
      </p>
      <p class="underL">
        (3) 儿童信息存储的地点、期限和到期后的处理方式发生实质性变化；
      </p>
      <p class="underL">(4) 儿童信息的安全保障措施发生实质性变化；</p>
      <p class="underL">(5) 拒绝同意的后果发生实质性变化；</p>
      <p class="underL">(6) 用户投诉、举报的渠道和方式发生实质性变化；</p>
      <p class="underL">(7) 更正、删除儿童信息的途径和方法发生实质性变化；</p>
      <p class="underL">(8) 其他可能对儿童信息权益产生重大影响的变化。</p>
      <p class="underL weight">
        如儿童或您在本声明更新生效后继续使用我们的产品或服务的，即表示儿童及您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。但是如果更新的内容需要获取超出本声明收集范围和使用目的的个人敏感信息，仍会再次以显著方式征求您的同意。
      </p>
      <p class="underL weight">
        9.若适用法律要求提供隐私政策的先前版本以供用户查阅，我们也将遵守相关的法律规定，就本隐私政策的旧版本进行存档，供您查阅。
      </p>
      <p class="underL weight">
        10.如本声明未规定之处，参照适用各产品《隐私政策》。在适用本协议的情况下，如本协议与各产品《隐私政策》的条款规定存在不一致的，以本协议为准。
      </p>
      <span class="underL weight"></span>
      <div class="h1">六、如何联系我们</div>
      <p>
        1.
        我们设置了专人负责儿童个人信息保护工作，将严格按照本声明保护儿童个人信息。如儿童或者其监护人对本声明存在任何疑问，或对于儿童信息处理存在任何问题、意见或建议，请通过以下渠道联系我们。
      </p>
      <p>公司名称：外语教学与研究出版社有限责任公司</p>
      <p>联系地址：北京市西三环北路19号外研社大厦研发中心</p>
      <p>联系邮箱：yanfa@fltrp.com</p>
      <p>客服电话：010-88819743</p>
      <p>（悦测评、外研拼读、悦教大讲堂）邮箱：wangjinmeng@fltrp.com</p>
      <p>（悦测评、外研拼读、悦教大讲堂）电话：010-88819188</p>
      <p>
        2.请监护人理解，为了保障安全，在响应监护人反馈的问题前，我们可能需要监护人提供必要的材料证明其监护人身份。我们将在收到反馈并验证监护人身份后的十五天内回复监护人的请求。如儿童和监护人不满意我们的处理，还可以向履行个人信息保护职责的部门进行投诉、举报。
      </p>
      <p>（正文完）</p>
    </div>
  </div>
</template>
<style scoped>
p {
  text-indent: 2rem;
}
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.weight {
  font-weight: 600;
}
ul {
  list-style-type: disc;
  padding-left: 3rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
